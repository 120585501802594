
import { Inject } from 'inversify-props';
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import CarsDotDropdown from '../cars-dot-dropdown.vue';

@Component({
    components: {
        CarsDotDropdown,
        RatesDayScanBtn,
    },
})
export default class RatesGraphTooltip extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        type: Boolean,
        default: () => true,
    })
    private withList!: boolean;

    @Prop({
        required: false,
        type: Function,
    })
    private freezeTooltip!: Function;

    handleDotsClick(e: Event) {
        e.stopPropagation();
    }

    handleTooltipClick() {
        this.$emit('click', this.day);
    }

    get items() {
        return [
            {
                link: `day-rate/${this.day}`,
                text: 'Details',
            },
            // {
            //     link: `demand-map/${this.day}`,
            //     text: 'Demand Map',
            // },
            // {
            //     link: `price-history/${this.day}`,
            //     text: 'Price History',
            // },
        ];
    }
}
